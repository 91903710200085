import $ from "jquery";
import {searchBar} from "../components/search_bar_new";
import {searchCore} from "../components/search_core_new";
import SearchFilters from "../components/search_filters_new";
import "../styles/main_new.less";

window.searchBar = searchBar;
window.searchCore = searchCore;
window.searchFilters = new SearchFilters();

window.main = {
    initialize: function () {
        // Mobile-specific adjustments
        if ($('#front-page-boxes').is(':hidden')) {
            $('#upper-main-container').css('background-image', "url('{% static 'img/mob_bg.jpg' %}')");
        }
    }
};
